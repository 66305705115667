import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ToyContext } from './contexts/ToyContext';

import Form from './components/Form';
import Toys from './components/Toys';

import { HOST } from './utils/config';

const AppWrapper = styled.div`
  text-align: center;
`;

function App() {
  const { setToys } = useContext(ToyContext);

  useEffect(() => {
    fetch(`${HOST}get-entries.php`)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          setToys(json.data);
        } else {
          alert(json.error.message);
        }
      });
  }, [setToys]);

  return (
    <AppWrapper>
      <h1>Hinzufügen</h1>
      <Form />
      <hr />
      <Toys />
    </AppWrapper>
  );
}

export default App;
