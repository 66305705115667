import React, { useRef } from 'react';
import styled from 'styled-components';

interface LabelInterface {
	title: string;
	value: string | null;
	onChange: (value: string) => void;
};

const Wrapper = styled.div`
	margin-top: 5px;
`;

const Label = styled.label`
	display: block;
	font-size: 12px;
`;

const Input = styled.input`
	border: 0;
	border-bottom: 1px solid #ccc;
	display: block;
	width: 100%;

	&:focus,
	&:hover {
		border-bottom-color: #000;
	}
`;

export default function({
	title,
	value,
	onChange,
}: LabelInterface) {
	const inputRef = useRef<HTMLInputElement>(null);
	let timeout: number;

	return (
		<Wrapper>
			<Label>{title}:</Label>
			<Input
				defaultValue={value || ''}
				type="date"
				onChange={e => {
					clearTimeout(timeout);
					timeout = setTimeout(() => {
						if (inputRef?.current) {
							onChange(inputRef.current!.value || '');
						}
					}, 300);
				}}
				ref={inputRef}
			/>
		</Wrapper>
	);
}
