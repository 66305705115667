import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
	background-color: #00f;
	border: 0;
	border-radius: 3px;
	color: #fff;
	margin: 5px;
`;

export interface SortButtonInterface {
	name: string;
	onClick: () => void;
};

export default ({ name, onClick }: SortButtonInterface) => (
	<Button
		type="button"
		onClick={onClick}
	>
		{name}
	</Button>
);

