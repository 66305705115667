import React from 'react';
import styled from 'styled-components';

import ToyImage from './ToyImage';
import ToyLabel from './ToyLabel';
import ToyDate from './ToyDate';

import { HOST } from '../../utils/config';

export interface ToyInterface {
	id: number;
	name: string;
	image: string;
	from: string;
	date: string;
	birthday: string;
	type: string;
	onUpdate?: (toy: ToyInterface) => void;
};

const Toy = styled.div`
	border-radius: 5px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	margin: 5px;
	overflow: hidden;
	width: 200px;
`;

const ToyValues = styled.div`
	padding: 5px 10px 10px;
`;

export default function ToyComponent({
	id,
	name,
	image,
	from,
	date,
	birthday,
	type,
	onUpdate,
}: ToyInterface) {
	const toy: ToyInterface = { id, name, image, from, date, birthday, type };
	const updateValue = (type: 'name' | 'from' | 'date' | 'birthday' | 'type') => (value: string) => {
		toy[type] = value;
		fetch(`${HOST}update-entry.php`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(toy),
		}).then(response => response.json())
		.then(json => {
			if (json.success) {
				onUpdate?.(json.data);
			} else {
				console.warn(json.error);
			}
		}).catch(error => {
			console.warn(error);
		});
	};

	return (
		<Toy data-id={id}>
			<ToyImage src={`images/${image}`} alt={name || ''} />
			<ToyValues>
				<ToyLabel title="Name" value={name} onChange={updateValue('name')} />
				<ToyLabel title="Bekommen von" value={from} onChange={updateValue('from')} />
				<ToyDate title="Geburtstag" value={birthday} onChange={updateValue('birthday')} />
				<ToyDate title="Bekommen am" value={date} onChange={updateValue('date')} />
				<ToyLabel title="Art" value={type} onChange={updateValue('type')} />
			</ToyValues>
		</Toy>
	);
}
