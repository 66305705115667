import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Toy, { ToyInterface } from './Toy';
import SortButton from '../SortButton';

import { ToyContext } from '../../contexts/ToyContext';

type SortingType = {
	key: 'id' | 'name' | 'type';
	name: string;
};

const Toys = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: flex-start;
`;

const sortingFn = (a: string | number, b: string | number, asc: boolean) => {
	if (a < b) {
		if (asc) {
			return -1;
		}
		return 1;
	}
	if (a > b) {
		if (asc) {
			return 1;
		}
		return -1;
	}
	return 0;
}

export default function ToysComponent() {
	const { toys, setToys } = useContext(ToyContext);
	const [sorting, setSorting] = useState<'id' | 'name' | 'type'>('id');
	const [ascending, setAscending] = useState<boolean>(false);

	const sortingOptions: SortingType[] = [
		{ key: 'id', name: 'Erstellung' },
		{ key: 'name', name: 'Name' },
		{ key: 'type', name: 'Art' },
	];

	const sortingButtons = sortingOptions.map((entry: SortingType) => (
		<SortButton
			key={entry.key}
			name={entry.name}
			onClick={() => {
				if (sorting === entry.key) {
					setAscending(!ascending);
				} else {
					setSorting(entry.key);
					setAscending(true);
				}
			}}
		/>
	));

	const toyComponents = toys
		.sort((a: ToyInterface, b: ToyInterface) => {
			const cmpValue = sortingFn(a[sorting], b[sorting], ascending);
			if (cmpValue === 0) {
				return sortingFn(a.name, b.name, true);
			}
			return cmpValue;
		})
		.map((toy: ToyInterface) => (
			<Toy
				key={`toy-${toy.id}`}
				id={toy.id}
				name={toy.name}
				image={toy.image}
				from={toy.from}
				date={toy.date}
				birthday={toy.birthday}
				type={toy.type}
				onUpdate={(newToy: ToyInterface) => {
					const newToys = toys.map((oldToy: ToyInterface) => {
						if (oldToy.id === newToy.id) {
							return newToy;
						}
						return oldToy;
					});
					setToys(newToys);
				}}
			/>
		)
	);

	return (
		<div>
			<header>
				<div>{toys.length} Einträge</div>
				<div>Sortieren nach: {sortingButtons}</div>
			</header>
			<Toys>
				{toyComponents}
			</Toys>
		</div>
	);
}
