import React, { ReactNode, createContext, useState } from 'react';

import { ToyInterface } from '../components/Toys/Toy';

interface ContextProps {
	toys: ToyInterface[];
	setToys: (toys: ToyInterface[]) => void;
}

export const ToyContext = createContext<ContextProps>({ toys: [], setToys: toys => {} });

export function ToyContextProvider({ children }: { children: ReactNode }) {
	const [toys, setToys] = useState<ToyInterface[]>([]);

	return (
		<ToyContext.Provider
			value={{
				toys,
				setToys,
			}}
		>
			{children}
		</ToyContext.Provider>
	);
}
