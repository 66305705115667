import styled from 'styled-components';

export const Input = styled.input`
	visibility: hidden;
	width: 0;
	height: 0;
`;

export const Label = styled.label`
	border: 1px solid #000;
	display: inline-block;
	margin-bottom: 10px;
	padding: 6px 12px;
	min-width: 120px;
`;
