import React, { useContext, useRef } from 'react';

import { Input, Label } from './FileInput';

import { ToyContext } from '../../contexts/ToyContext';

import { HOST } from '../../utils/config';

export default function Form() {
  const { toys, setToys } = useContext(ToyContext);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <form
      encType="multipart/form-data"
      ref={formRef}
    >
      <Input
        id="add-new-image"
        type="file"
        name="image[]"
        onChange={e => {
          e.preventDefault();
          if (formRef?.current) {
            const formData = new FormData(formRef.current);

            fetch(`${HOST}add-entries.php`, {
              method: 'POST',
              body: formData,
            }).then(response => response.json())
            .then(json => {
              if (json.success) {
                setToys([ ...json.data, ...toys ]);
                formRef.current!.reset();
              } else {
                alert('Fehler beim Hinzufügen: ' + json.error.message);
              }
            });
          }
        }}
        multiple
        required
      />
      <Label htmlFor="add-new-image">Hinzufügen +</Label>
    </form>
  );
}
